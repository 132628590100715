import {extractIndoorMapLink} from "../util/util";
import GoogleMapLoader from "./map/GoogleMapLoader";

const Maps = ({gps: {gpsLat, gpsLong}, locations, reportedGps, topEmlLocation = "", aps}) => {
    let hrl = topEmlLocation.humanReadableLocation || "";
    hrl = hrl.replace(/(\n)+/g, "\n");
    let {strippedHrl} = extractIndoorMapLink(hrl);
    const isEliOfficeLocation = strippedHrl.toLocaleLowerCase().indexOf("gaylord trail") !== -1;
    return <div className="flex flex-col">
        {topEmlLocation && <div className="py-1 px-2 mb-2">
            <div className="text-lg mt-2 font-medium text-slate-700 flex flex-row">
                <div className="w-1/2 flex flex-col">
                    <span className="font-medium text-slate-600">
                        Dispatchable Address
                    </span>
                    {format(topEmlLocation.dispatchableAddress)}
                </div>
                <div className="font-medium text-blue-800 mx-auto flex flex-col">
                    <span className="font-medium text-slate-600">
                        Tag
                    </span>
                    <div>{topEmlLocation.tagUsed} (SSID: {topEmlLocation.ssid})</div>
                </div>
            </div>
        </div>
        }
        <div className="w-screen h-screen max-w-full max-h-full z-5 pb-2">
            <GoogleMapLoader
                isEliOfficeLocation={isEliOfficeLocation}
                locations={locations}
                reportedGps={reportedGps}
                aps={aps}
                gpsLatLong={{lat: gpsLat, lng: gpsLong}}
                width="400px"
                height="400px"
            />
        </div>
    </div>
};

export default Maps;

const format = (address) => {
    const {businessName, civicNumber, streetName, unit, room, floor, borough, stateOrProvince, country} = address || {};
    let parts = [];
    if (businessName) parts.push(businessName);
    if (civicNumber) parts.push(civicNumber);
    if (streetName) parts.push(streetName);
    if (unit) parts.push(unit);
    if (room) parts.push(room);
    if (floor) parts.push("Level " + floor);
    if (borough) parts.push(borough);
    if (stateOrProvince) parts.push(stateOrProvince);
    if (country) parts.push(country);

    return parts.join(", ");
}