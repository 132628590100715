import React from "react";
import {useQuery} from "@tanstack/react-query";
import {getCallDetails} from "../api/calls";
import CallHistoryRaw from "./CallHistoryRaw";
import CallHistory from "./CallHistory";
import Maps from "./Maps";

const Tabs = ({isOpen, color = "slate", callUuid}) => {
    const tabTitles = ["EML", "History", "Raw Data"];
    const [openTab, setOpenTab] = React.useState(0);
    const {isError, data: response} = useQuery({
        queryKey: ["get-call-detail-" + callUuid, {callUuid}],
        queryFn: getCallDetails,
        enabled: isOpen,
        refetchInterval: 10000, // refetch every 10 sec,
        refetchIntervalInBackground: true
    });

    if (!isOpen || !response || !response.length) return <></>;

    if (isError) {
        return <>
            <svg className="h-5 w-5 mr-1 text-red-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                      d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"/>
            </svg>
            <span className="text-lg text-red-400">There was some problem while loading the data, please try refreshing the page.</span>
        </>;
    }

    response[0].eml.forEach(callPushData => {
        let payload = callPushData.payload;
        const hasResolvedLocations = !!(payload && payload.length && payload[0].rankedLocations.length);
        // sort by confidence
        if (hasResolvedLocations) {
            payload[0].rankedLocations.sort((l1, l2) => l1.confidence > l2.confidence ? -1 : 1);
        }
    });

    const mostRecentCallPush = response[0].eml && response[0].eml.length ? response[0].eml[0] : {payload: [{rankedLocations: [{}]}]};
    const oldestPayload = response[0].eml && response[0].eml.length
        ? response[0].eml[response[0].eml.length - 1]
        : {};
    const locations = response[0].eml && response[0].eml.length
        ? response[0].eml
            .filter(e => e.payload[0].gpsEstimated)
            .map(e => ({
                lat: e.payload[0].gpsLatitude,
                lng: e.payload[0].gpsLongitude,
                floor: e.payload[0].rankedLocations[0].dispatchableAddress.floor}))
        : [];
    const reportedGps = response[0].eml && response[0].eml.length
        ? response[0].eml.map(e => ({
            lat: e.payload[0].gpsReportedLatitude,
            lng: e.payload[0].gpsReportedLongitude,
            floor: e.payload[0]?.rankedLocations[0]?.dispatchableAddress?.floor
        }))
        : [];
    const aps = response[0].eml && response[0].eml.length
        ? response[0].eml
            .filter(e => e.payload[0]?.rankedLocations[0]?.tagGpsLatitude
                && e.payload[0]?.rankedLocations[0]?.tagGpsLongitude
                && e.payload[0]?.rankedLocations[0]?.dispatchableAddress)
            .map(e => {
                const loc = e.payload[0].rankedLocations[0];
                return {
                    bssid: loc.tagUsed,
                    ssid: loc.ssid,
                    lat: loc.tagGpsLatitude,
                    lng: loc.tagGpsLongitude,
                    floor: loc.dispatchableAddress.floor
                };
            })
        : [];
    const tabChildren = [<Maps
        topEmlLocation={mostRecentCallPush.payload[0].rankedLocations[0]}
        gps={{
            "gpsLat": oldestPayload.payload[0].gpsLatitude,
            "gpsLong": oldestPayload.payload[0].gpsLongitude
        }}
        locations={locations}
        reportedGps={reportedGps}
        aps={aps}
    />,
        <CallHistory callUuid={callUuid} history={response[0].eml}/>,
        <CallHistoryRaw callUuid={callUuid} history={response[0].eml}/>
    ];
    return (
        <>
            <div className="flex flex-wrap w-full">
                <div className="w-full">
                    <ul
                        className="flex mb-0 list-none w-full flex-wrap pt-1 pb-1 flex-row"
                        role="tablist"
                    >
                        {
                            tabTitles.map(
                                (tab, index) => <li
                                    className="last:mr-0 w-1/3 flex-auto text-center"
                                    key={"tab_" + index}
                                    id={"tab_" + index}>
                                    <a
                                        className={
                                            "text-xs mx-1 mt-1 font-bold uppercase px-5 py-3 rounded block leading-normal " +
                                            (openTab === index
                                                ? "text-black bg-gray-300"
                                                : "text-" + color + "-600 bg-blue-300")
                                        }
                                        onClick={e => {
                                            e.preventDefault();
                                            setOpenTab(index);
                                        }}
                                        data-toggle="tab"
                                        href={"#link" + index}
                                        role="tablist"
                                    >
                                        {tab}
                                    </a>
                                </li>)
                        }
                    </ul>
                    <div className="relative flex flex-col break-words bg-gray-300 w-full mb-1 rounded">
                        <div className="py-5 flex-auto">
                            <div className="tab-content tab-space">
                                {
                                    tabChildren.map(
                                        (tabChild, index) => <div
                                            className={openTab === index ? "block" : "hidden"}
                                            key={"link" + index}
                                            id={"link" + index}>
                                            {tabChild}
                                        </div>
                                    )
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Tabs;