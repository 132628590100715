import {useEffect, useRef} from "react";
import {useLeafletContext} from "@react-leaflet/core";
import GeoRasterLayer from "georaster-layer-for-leaflet";
import {loadFloorPlan} from "../../util/EliFloorPlans";

const GeoTiffLayer = ({url, options, setLoaded, isVisible = false}) => {
    const geoTiffLayerRef = useRef();
    const loading = useRef(false);
    const context = useLeafletContext();

    // load layer
    useEffect(() => {
        if (loading.current) return;

        loading.current = true;
        loadFloorPlan(url)
            .then(georaster => {
                options.georaster = georaster;
                geoTiffLayerRef.current = new GeoRasterLayer(options);
                setLoaded(true);
            });
        return () => {
        };
    }, [context, url, options, setLoaded]);

    // hide/show layer
    useEffect(() => {
        const container = context.layerContainer || context.map;
        if (geoTiffLayerRef.current) {
            if (isVisible) {
                container.addLayer(geoTiffLayerRef.current)
            } else {
                container.removeLayer(geoTiffLayerRef.current)
            }
        }
    }, [isVisible, context]);
    return null;
};

export default GeoTiffLayer;
