import parseGeoraster from "georaster";
import proj4 from "proj4-fully-loaded";

window.proj4 = proj4.default;
const cachedPlans = {};

const loadFloorPlan = async (url) => {
    if (cachedPlans[url]) return cachedPlans[url];

    const img = await fetch(url)
    const buffer = await img.arrayBuffer();
    const geoRaster = await parseGeoraster(buffer);
    cachedPlans[url] = geoRaster;
    return geoRaster;
}

export {loadFloorPlan};